import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #121212e8;
  z-index: 10;
  overflow: auto;
  padding: 40px;
  .overlay-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    .overlay-title {
      flex: 1;
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      color: white;
    }
    .overlay-close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-size: 40px;
      cursor: pointer;
      color: white;
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .overlay-title-container {
      margin-bottom: 40px;
      .overlay-title {
        font-size: 24px;
      }
      .overlay-close-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .overlay-title-container {
      margin-bottom: 25px;
      .overlay-close-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
`
