import React from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'

import OverlayStyled from './styled'

class Overlay extends React.PureComponent {
  getOverlayTitle() {
    return (
      <div className="overlay-title-container">
        <div id={`${this.props.id}-title`} data-test={`${this.props.id}-title`} className="overlay-title">
          {this.props.title}
        </div>
        <div id={`${this.props.id}-close-btn`} data-test={`${this.props.id}-close-btn`} className="overlay-close-icon" onClick={this.props.onClose}>
          <MdClose />
        </div>
      </div>
    )
  }

  handleOverlayClicked = () => {
    if (this.props.onOverlayClick) {
      this.props.onOverlayClick()
    }
  }

  handleContentBoxClicked = e => {
    e.stopPropagation()
  }

  render() {
    return (
      <OverlayStyled id={this.props.id} data-test={this.props.id} className={this.props.overlayClassName} onClick={this.handleOverlayClicked}>
        <div
          id={`${this.props.id}-content`}
          data-test={`${this.props.id}-content`}
          className={this.props.className}
          onClick={this.handleContentBoxClicked}
        >
          {this.getOverlayTitle()}
          {this.props.children}
        </div>
      </OverlayStyled>
    )
  }
}

Overlay.defaultProps = {
  id: 'overlay',
  title: '',
  overlayClassName: '',
}

Overlay.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]).isRequired,
}

export default Overlay
