const TABLET_SCREEN_WIDTH_SIZE = 576
const LARGE_TABLET_SCREEN_WIDTH_SIZE = 1280
const DESKTOP_SCREEN_WIDTH_SIZE = 1440
const LARGE_DESKTOP_SCREEN_WIDTH_SIZE = 1920

export default {
  minSizeS: `${TABLET_SCREEN_WIDTH_SIZE}px`,
  maxSizeS: `${LARGE_TABLET_SCREEN_WIDTH_SIZE - 1}px`,
  minSizeM: `${LARGE_TABLET_SCREEN_WIDTH_SIZE}px`,
  maxSizeM: `${DESKTOP_SCREEN_WIDTH_SIZE - 1}px`,
  minSizeL: `${DESKTOP_SCREEN_WIDTH_SIZE}px`,
  maxSizeL: `${LARGE_DESKTOP_SCREEN_WIDTH_SIZE - 1}px`,
  minSizeXL: `${LARGE_DESKTOP_SCREEN_WIDTH_SIZE}px`,
  // TODO:  Refactor css style all project
  sizeS: `${LARGE_TABLET_SCREEN_WIDTH_SIZE - 1}px`,
  sizeM: `${DESKTOP_SCREEN_WIDTH_SIZE - 1}px`,
  sizeL: `${LARGE_DESKTOP_SCREEN_WIDTH_SIZE - 1}px`,
  SizeXL: `${LARGE_DESKTOP_SCREEN_WIDTH_SIZE}px`,
}
